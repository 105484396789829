.facility-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #f4f6f5;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.text-center {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.text-center h1 {
  font-size: 24px;
  color: #2e3191;
}

.facility-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.col-lg-3,
.col-sm-6 {
  flex: 1 1 21%;
  max-width: 21%;
  margin: 10px;
  box-sizing: border-box;
}

.facility-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  padding: 20px;
}

.facility-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.facility-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: white;
  font-size: 2rem;
  margin: 0 auto 20px;
}

.bg-primary {
  background-color: #007bff;
}

.bg-success {
  background-color: #28a745;
}

.bg-warning {
  background-color: #ffc107;
}

.bg-info {
  background-color: #17a2b8;
}

.facility-text h3 {
  margin-bottom: 15px;
}

.facility-text p {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 1200px) {
  .text-center {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .text-center {
    width: 90%;
  }
  .col-lg-3,
  .col-sm-6 {
    flex: 1 1 45%;
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  .facility-container {
    padding: 20px;
  }
  .text-center {
    width: 100%;
  }
  .facility-row {
    flex-direction: column;
    align-items: center;
  }
  .col-lg-3,
  .col-sm-6 {
    flex: 1 1 90%;
    max-width: 90%;
    margin: 10px 0;
  }
  .mb-5 p {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
}

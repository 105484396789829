.contact-main-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 40px;
}

.contact-card-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  padding: 20px;
  margin-right: 20px;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  width: 30%;
  cursor: pointer;
}

.contact-card-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.contact-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #ee7d27;
  font-size: 2rem;
  margin: 0 auto 20px;
}

.contact-icon {
  width: 50px;
  height: 50px;
}

.contact-text h3 {
  margin-bottom: 15px;
  font-size: 1.4rem;
}

.contact-text p {
  font-size: 1rem;
  color: #1e1e1e;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-cards-row {
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
  }

  .contact-card-item {
    width: 90%;
    margin: 0;
    margin-bottom: 20px;
    align-self: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .contact-text h3 {
    font-size: 1.2rem;
  }

  .contact-text p {
    font-size: 0.9rem;
  }
}

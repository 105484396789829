/* Gallery Styles */

.pm-section {
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.pm-photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.pm-photo {
  position: relative;
  width: 30%; /* Increased width */
  height: 250px; /* Increased height */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

.pm-photo:hover {
  transform: scale(1.1);
}

.pm-no-data-container {
  display: flex;
  height: 30vh;
}

.pm-no-data {
  margin: auto;
  color: #2e3191;
  font-size: 1.25em;
  font-weight: 700;
}

/* Photo Modal Styles */
.pm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pm-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent; /* Remove white background */
  border-radius: 8px;
  overflow: hidden;
}

.pm-photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pm-photo-container img {
  max-width: 80%;
  max-height: 90vh;
  border-radius: 8px;
}

.pm-prev-button,
.pm-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
}

.pm-prev-button {
  left: 10px;
}

.pm-next-button {
  right: 10px;
}

.pm-prev-button:hover,
.pm-next-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.pm-modal-content p {
  color: white;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .pm-photo {
    position: relative;
    width: 100%; /* Increased width */
    height: 250px; /* Increased height */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s;
  }
}

.slider-container {
  width: 90%;
  margin: 15px auto;
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 33rem;
  object-fit: cover;
}

@media (max-width: 768px) {
  .slider-image {
    width: 100%;
    height: 15rem;
  }
}

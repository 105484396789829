.director-msg-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: 0;
  padding-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
  background-color: #f4f6f5;
}

.director-msg-section {
  flex: 1;
  margin-right: 20px;
}

.director-msg-title {
  color: #2e3191;
  font-size: 24px;
  margin-bottom: 10px;
}

.director-title-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.director-msg-content {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.director-msg-name {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 5px;
  color: #555;
  font-weight: 500;
}

.director-title {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.read-more-btn {
  background-color: #ee7d27;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.read-more-btn:hover {
  background-color: #c62828;
}

.director-msg-img-section {
  flex: 1;
  text-align: center;
}

.director-msg-image {
  width: 65%;
  height: 24rem;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .director-msg-container {
    flex-direction: column;
    padding: 20px;
  }

  .director-msg-section,
  .director-msg-img-section {
    margin: 10px 0;
  }

  .director-msg-title,
  .director-title-title {
    text-align: left;
  }
  .director-msg-title {
    margin-bottom: 5px;
  }

  .director-title-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .director-msg-image {
    height: auto;
  }

  .director-msg-content {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .director-msg-name {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 5px;
    color: #555;
    font-weight: 500;
  }

  .director-title {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
}

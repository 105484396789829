.principal-message {
  display: flex;
  padding: 40px;
  background-color: #f4f6f5;
}

.pm-centred-div {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.ftd-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 50px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.ftd-content h2 {
  color: #ee7d27;
  font-size: 2em;
  margin-bottom: 10px;
}

.principal-name {
  font-size: 1.1em;
  color: #000000;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 20px;
}

.quote-box {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 150px;
  border-radius: 7.5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quote-box-left {
  border-left: 4px solid #ee7d27;
  height: 40px;
  transition: all 0.3s ease;
}

.quote-box:hover .quote-box-left {
  border-left: 4px solid #ee7d27;
  height: 80px;
  transition: all 0.3s ease;
}

.quote-box-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 60px 20px;
}

.quote-box-right img {
  width: 30px;
  height: 30px;
  color: #ee7d27;
}

.quote-text {
  font-size: 1em;
  margin-top: 10px;
  margin-left: 20px;
  font-style: italic;
}

.quote-author {
  font-size: 1em;
  margin-top: 20px;
  margin-left: 20px;
}

.ftd-message p {
  text-align: justify;
  /* margin-top: 40px; With quote box */
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.6;
}

.ftd-image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.ftd-image-container img {
  max-width: 100%;
  max-height: 510px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.ftd-image-container p {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 1em;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .principal-message {
    padding: 20px;
    flex-direction: column;
  }

  .pm-centred-div {
    flex-direction: column;
    width: 100%;
  }

  .principal-name {
    margin-bottom: 10px;
  }

  .ftd-content {
    margin-right: 0;
    margin-bottom: 0px;
  }

  .ftd-content h2 {
    font-size: 28px;
    margin-bottom: 0px;
  }

  .quote-box {
    flex-direction: column;
  }

  .quote-box-right {
    padding: 20px;
  }
  .ftd-message p {
    margin-bottom: 10px;
  }
}

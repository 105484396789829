.main-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.inside-container {
  display: flex;
  width: 80%;
  margin: 80px auto 80px;
  background-color: #2e3191;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.map-container {
  width: 100%;
}

.info-container {
  margin: 20px 40px 20px 60px;
  display: flex;
  flex-direction: column;
}

.info-container > h1 {
  color: #000000;
}

.paraText {
  font-size: large;
  color: #ffffff;
  margin-top: 20px;
  word-spacing: 1px;
}

.contact-inputs {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.container {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.text-container {
  margin-bottom: 14px;
  width: 100%;
}

.header-input-texts {
  color: #000000;
  font-size: medium;
  font-weight: 600;
}

.input-style {
  height: 38px;
  width: 85%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
}

.input-style2 {
  width: 85%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.buttonStyle {
  height: 38px;
  background-color: #000000;
  border-radius: 6px;
  width: 30%;
  border-width: 0;
  font-weight: 500;
  color: #ffffff;
}

.buttonStyle:hover {
  background-color: #ffffff;
  color: #000000;
  font-weight: 700;
  transform: scale(0.9);
  font-size: 15px;
  cursor: pointer;
  transition: all 0.15s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inside-container {
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
  }

  .info-container {
    margin: 20px;
  }

  .info-container > h1 {
    color: #000000;
    font-size: 28px;
    margin-top: -10px;
  }

  .paraText {
    font-size: 16px;
    color: #ffffff;
    margin-top: 6px;
    word-spacing: 1px;
  }

  .input-style,
  .input-style2 {
    width: 100%;
  }

  .buttonStyle {
    width: 100%;
  }
  .header-input-texts {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-top: -8px;
  }
}

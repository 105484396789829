/* General Container Styles */
.examination-container {
  width: 80%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Section Styles */
.examination-section {
  margin-bottom: 30px;
}

/* Subtitle Styles */
.examination-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #2e3191;
}

.examination-subtitle svg {
  margin-right: 10px;
}

/* Table Styles */
.examination-table {
  width: 100%;
  border-collapse: collapse;
}

.examination-table th,
.examination-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.examination-table th {
  background-color: #f4f6f5;
  text-align: left;
}

/* Responsive Styles */
.table-responsive {
  width: 100%;
  overflow-x: auto;
}

/* Text Styles */
.examination-text {
  margin-top: 10px;
}

.examination-points-container {
  margin-top: 20px;
}

.examination-points {
  padding-left: 20px;
  margin-top: 10px;
}

.examination-points li {
  margin: 10px 0;
  line-height: 1.6;
  font-size: 1em;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .examination-container {
    width: 90%;
    padding: 10px;
  }

  .examination-subtitle {
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .examination-subtitle svg {
    margin-right: 5px;
  }

  .examination-table th,
  .examination-table td {
    padding: 2px;
    font-size: 14px;
  }

  .examination-table th {
    font-size: 15px;
  }

  .examination-points {
    padding-left: 10px;
    font-size: 14px;
  }

  .examination-points li {
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .examination-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .examination-table th,
  .examination-table td {
    padding: 4px;
    font-size: 12px;
  }

  .examination-table th {
    font-size: 13px;
  }
}

/* Gallery Styles */

.gallery {
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.albums-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.album {
  position: relative;
  width: 30%; /* Increased width */
  height: 250px; /* Increased height */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

.album:hover {
  transform: scale(1.05);
}

.album img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.album-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.album:hover .album-hover {
  opacity: 1;
}

.photo-container-back {
  display: flex;
  color: #2e3191;
  cursor: pointer;
  width: fit-content;
  margin: auto;
  margin-bottom: 35px;
}

.photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.photo {
  position: relative;
  width: 30%; /* Increased width */
  height: 250px; /* Increased height */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

.photo:hover {
  transform: scale(1.1);
}

/* Photo Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent; /* Remove white background */
  border-radius: 8px;
  overflow: hidden;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-container img {
  max-width: 80%;
  max-height: 90vh;
  border-radius: 8px;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.modal-content p {
  color: white;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .album {
    position: relative;
    width: 100%; /* Increased width */
    height: 250px; /* Increased height */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s;
  }
  .photo {
    position: relative;
    width: 100%; /* Increased width */
    height: 250px; /* Increased height */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s;
  }
}

.heading-main-container {
  background-color: #2e3191;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1.8rem;
  padding-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading-header-text {
  color: rgb(251, 251, 251);
  font-size: 2.5rem;
  text-align: center;
}
.heading-subText {
  color: rgb(250, 250, 250);
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
  .heading-main-container {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
    height: 170px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .heading-header-text {
    font-size: 2.2rem;
  }
  .heading-subText {
    margin-top: 6px;
  }
}

.about-container {
  padding: 40px 0px 40px 0px;
  padding-top: 20px;
}

.inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.img_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  padding: 10px;
}

.image_inside_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}

.imgStyle {
  width: 100%;
  height: auto;
  max-width: 680px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 10px;
}

.textStyle {
  line-height: 1.25em;
  color: #111111;
  margin-left: 2.4rem;
  font-size: xx-large;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 25px;
  font-family: Sans-serif;
}

.description {
  margin: 10px 40px 40px 40px;
}

.descriptionStyle {
  margin-top: 30px;
  margin-right: 180px;
  font-size: 16px;
  color: #505050;
}

@media (max-width: 768px) {
  .about-container {
    padding-top: 35px;
    padding: 20px;
  }
  .inner-container {
    flex-direction: column;
  }

  .img_container,
  .textContainer {
    flex-basis: 100%;
  }

  .image_inside_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .descriptionStyle {
    margin-left: 0;
    margin-right: 0;
  }
  .imgStyle {
    align-self: center;
    max-width: 95%;
  }
  .textContainer {
    padding: 0px;
  }

  .textStyle {
    line-height: 1.2em;
    color: #111111;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 25px;
    margin-left: 0px;
    font-family: Sans-serif;
    text-align: left;
  }

  .description {
    margin: 0px 0px;
  }
  .descriptionStyle {
    margin-top: 20px;
    font-size: 16px;
    color: #505050;
  }
}

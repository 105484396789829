.admission-screen-main-container {
  flex-direction: column;
  display: flex;
  flex: 1;
}

.inner-container-admission {
  display: flex;
  flex-direction: row;
  padding-left: 10rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.admission-picture-style {
  flex: 0.7;
  height: 27.5rem;
  width: 30rem;
  margin-left: 2rem;
  margin-right: 14rem;
}

.inner-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inner-header-text {
  font-size: 32px;
  font-weight: 600;
}

.inner-down-text-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.admission-logo-style {
  margin-right: 0.5rem;
  cursor: pointer;
  justify-self: center;
}

.pointer-text-container {
  flex-direction: row;
  display: flex;
  vertical-align: baseline;
  width: 34rem;
  margin-left: 1rem;
  margin-top: 4px;
  max-width: 90%;
  color: rgb(67, 67, 67);
}

.admissions-inner-btn-container {
  flex: 1 1 21%;
  margin: 10px;
  box-sizing: border-box;
  max-width: 60%;
  margin-top: 40px;
}

.admissions-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  padding: 20px;
  background-color: #2e3191;
  cursor: pointer;
}

.admissions-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.admissions-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #2e3191;
  font-size: 2rem;
  margin: 0 auto 10px;
}

.admission-bg-primary {
  background-color: rgb(255, 255, 255);
}

.admission-btn-text h3 {
  margin-bottom: 4px;
  color: rgb(255, 255, 255);
}

.admission-btn-text p {
  font-size: 0.9rem;
  color: rgb(255, 255, 255);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .inner-container-admission {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
  }

  .admission-picture-style {
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 2rem;
  }

  .inner-text-container,
  .pointer-text-container {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0;
  }

  .admissions-inner-btn-container {
    max-width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .admissions-item {
    padding: 15px;
  }

  .admission-btn-text h3 {
    font-size: 1.5rem;
  }

  .admission-btn-text p {
    font-size: 0.8rem;
  }

  .inner-header-text {
    font-size: 26px;
    text-align: center;
  }

  .pointer-text-container {
    text-align: left;
    width: auto;
    margin-left: 0rem;
    margin-top: 2px;
    margin-bottom: 8px;
    max-width: 100%;
  }

  .admission-picture-style {
    height: auto;
    max-width: 100%;
    border-radius: 10px;
  }
}

.coc-screen-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.coc-img-style {
  width: 70%;
  height: 25rem;
  border-radius: 20px;
  margin-top: 20px;
  display: block; /* Ensures the image is centered */
  margin-left: auto;
  margin-right: auto;
}

.code-of-conduct-content {
  width: 90%;
  max-width: 1200px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: left; /* Ensures text is left-aligned within the cards */
}

.card {
  background-color: #f9f9f9; /* Lighter background for cards */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow */
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease; /* Adds a smooth transform transition */
}

.card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

.card-title {
  font-size: 1.75rem; /* Slightly larger font size */
  margin-bottom: 10px;
  color: #333; /* Darker color for title */
}

.card-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #555; /* Softer color for text */
}

.card-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.card-content ul li {
  margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .coc-img-style {
    width: 90%;
    height: auto;
    border-radius: 20px;
    margin-top: 20px;
    display: block; /* Ensures the image is centered */
    margin-left: auto;
    margin-right: auto;
  }

  .code-of-conduct-content {
    width: 100%;
    padding: 0 10px;
  }

  .card {
    padding: 15px;
  }

  .card-title {
    font-size: 1.1rem;
    width: 90%;
  }

  .card-content {
    font-size: 0.95rem;
  }
}

.academics-screen-main-container {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.inner-container-academics {
  display: flex;
  flex-direction: row;
  padding-left: 10rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

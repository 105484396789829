.management-container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.management-section-title {
  font-size: 24px;
  color: #2e3191;
}

.management-highlight {
  color: #ee7d27;
}

.management-sub-title {
  font-size: 24px;
  color: #2e3191;
  margin-bottom: 40px;
}

.management-profile-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.management-profile-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  max-width: 300px;
  text-align: center;
  overflow: hidden;
  border-bottom: 2px solid #2e3191;
}

.management-profile-card img {
  width: 95%;
  height: 280px;
  border-radius: 50%;
  margin: 2.5%;
  object-fit: cover;
}

.management-profile-info {
  padding: 15px;
  background-color: #2e3191;
  border-top: 2px solid #2e3191;
  flex: 1;
}

.management-profile-info h4 {
  margin: 10px 0;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .management-profile-container {
    display: flex;
    flex-direction: column;
  }
}

.menu-area {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  min-height: 50px;
  line-height: 50px;
  background: #fff;
  z-index: 999;
}

.menu-area.scrolled {
  position: fixed;
  right: 0;
  left: 0;
  top: -15px;
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.0509803922);
  box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.0509803922);
  transform: translateY(15px) scale(1.005);
  transition: all 0.7s ease-in-out;
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  transition: padding 0.3s ease-in-out;
}

.row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.col-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.col-cell.extra {
  width: 200px;
  cursor: pointer;
  justify-content: flex-start;
}

.col-cell a {
  margin: 0 15px;
  text-decoration: none;
}

.col-cell.logo img {
  max-height: 100px;
  cursor: pointer;
}

.col-cell.extra {
  cursor: pointer;
}

.headerText {
  font-size: medium;
  font-weight: 600;
  color: #2e3191;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  user-select: none;
}

.headerText.active {
  color: #ee7d27;
}

.headerText:hover {
  color: #ee7d27;
}

.headerText:hover .p-logo-style {
  color: #ee7d27;
  transition: all 0.1 ease;
}

.dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  background-color: white;
  box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.05);
  z-index: 10;
  border-bottom: 4px solid #ee7d27;
  width: 250px;
}

.dropdown div {
  padding: 2px 10px;
  cursor: pointer;
  white-space: nowrap;
  color: #000000;
}

.dropdown div:hover {
  padding: 2px 10px;
  cursor: pointer;
  white-space: nowrap;
  color: #ee7d27;
}

.p-logo-style {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: #2e3191;
  cursor: pointer;
}

.menu-button {
  display: none;
}

.drawer {
  display: none;
}

@media (max-width: 768px) {
  .container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    transition: padding 0.3s ease-in-out;
  }

  .col-cell.extra {
    display: none; /* Hide the regular navbar links */
  }

  .col-cell.links {
    display: none; /* Hide the regular navbar links */
  }

  .col-cell.logo img {
    max-height: 70px; /* Reduce logo size for smaller screens */
  }

  .menu-button {
    display: block;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #ee7d27;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1031;
    padding: 10px;
  }

  .close-button {
    display: flex;
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #ee7d27;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }

  .drawer-list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }

  .drawer-list li {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    cursor: pointer;
    border-bottom: #ccc solid 1px;
    width: 95%;
    align-self: center;
  }

  .drawer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .drawer-item-toggle {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: #ee7d27;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
  }

  .nested-list {
    list-style: none;
    padding: 0 15px;
    margin: 10px 0 0 0;
    transition: all 0.3s ease-out;
  }

  .nested-list li {
    padding: 10px 0;
    cursor: pointer;
    border: 0;
    font-size: 15px;
  }
}

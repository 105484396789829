.section-padding {
  padding: 80px 0px;
}

.section-header {
  width: 60%;
  margin: 0 auto;
  align-self: center;
}

.line {
  height: 2px;
  width: 50px;
  background: #2e3191;
  display: block;
  margin: 20px auto 20px;
}

.section-header h3 {
  text-align: center;
  color: #2e3191;
  font-size: 24px;
}

.section-header p {
  color: #818992;
  font-size: 17px;
  text-align: center;
}

.feature-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.feature-left-container {
  margin-right: 80px;
}

.section-content {
  margin-top: 80px;
}

.single-feature {
  margin-bottom: 80px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 300px;
}

.single-feature h5 {
  font-size: 16px;
}

.media-right-margin {
  margin-right: 25px;
}

.text-right h5 {
  text-align: right;
}

.text-right p {
  text-align: right;
}

.text-left h5 {
  text-align: left;
}

.text-left p {
  text-align: left;
}

.single-feature p {
  font-size: 13px;
}

.feature-mobile {
  flex-direction: column;
  display: flex;
}

.img-fluid {
  height: 36rem;
  width: 22rem;
  cursor: pointer;
}

.playstore-img-fluid {
  height: 3rem;
  width: 10rem;
  margin-left: 50px;
  border-radius: 20px;
  margin-top: -60px;
  cursor: pointer;
}

.icon-border {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  color: #2e3191;
  font-size: 18px;
  line-height: 50px;
  border: 1px solid #2e3191;
}

.feature-img {
  width: 40px;
  height: 20px;
}

@media (max-width: 768px) {
  .section-padding {
    padding: 20px 0px;
  }
  .section-header {
    width: 100%;
  }
  .section-header p {
    padding-left: 20px;
    padding-right: 20px;
    color: #000000;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .single-feature {
    display: none;
  }
  .img-fluid {
    height: auto;
    width: 100%;
    align-self: center;
  }
  .playstore-img-fluid {
    height: auto;
    width: 50%;
    margin-left: 40px;
    border-radius: 20px;
  }
}

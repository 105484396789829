.h-header-container {
  background-color: #2e3191;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.h-headerInfos {
  color: white;
  flex-direction: row;
  display: flex;
  font-size: small;
}
.h-gmail {
  margin-left: 3rem;
  margin-right: 1rem;
  cursor: pointer;
}
.h-gmail:hover {
  color: #ee7d27;
}
.h-mobile {
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.h-mobile:hover {
  color: #ee7d27;
}
.p-header-logo-style {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: white;
  cursor: pointer;
}
.h-containerright {
  margin-right: 4rem;
}
.h-containerright a {
  margin-right: 10px;
}
.h-containerright a svg:hover {
  color: #ee7d27;
}
.h-logo-style {
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: white;
  cursor: pointer;
}
@media (max-width: 650px) {
  .h-header-container {
    display: none;
  }
}

.footer-container {
  background-color: #1c335f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 100px;
  padding-left: 100px;
  margin: 0 auto;
  background-image: url("https://keenitsolutions.com/products/wordpress/educavo/wp-content/uploads/2021/04/1051.png");
  background-position: center;
}
.footer-main-container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #1c335f;
  color: #ffffff;
}
.footer-logo,
.footer-description,
.footer-address,
.footer-quick-links,
.footer-map {
  margin-bottom: 20px;
}
.footer-logo img {
  width: 120px;
  margin-top: 20px;
}
.footer-description {
  flex: 1;
}
.footer-description p {
  margin: 0;
  width: 18rem;
  margin-bottom: 30px;
}
.footer-address {
  margin-left: 50px;
  width: 30rem;
}
.footer-address h3,
.footer-quick-links h3 {
  color: #ee7d27;
  margin-top: 60px;
}
.footer-quick-links {
  flex-direction: column;
  display: flex;
  margin-right: 40px;
}
.footer-address p,
.footer-quick-links a {
  margin: 0;
  color: #ffffff;
  text-decoration: none;
  margin-top: 20px;
}
.footer-address p:hover {
  color: #ee7d27;
}
.footer-address p:not(:last-child) {
  margin-bottom: 10px;
}
.footer-quick-links a:hover {
  color: #ee7d27;
}
.footer-map iframe {
  width: 300px;
  height: 320px;
  border: none;
  align-self: center;
  margin-top: 60px;
}
footer {
  background-color: #142840;
  text-align: center;
  padding: 10px 0;
}
footer a {
  color: #ee7d27;
  text-decoration: none;
  margin: 0 10px;
}
footer a:hover {
  text-decoration: underline;
}
.footer-social-icons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.footer-social-icons div {
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: 15px;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.footer-social-icons div:hover {
  color: #ee7d27;
  cursor: pointer;
}
.footer-logo-style {
  vertical-align: middle;
  margin-right: 0.5rem;
  color: #ffffff;
  cursor: pointer;
}
.footer-link {
  color: inherit;
  text-decoration: none;
}
.nohover-style:hover {
  color: #ffffff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    background-position: top;
  }
  .footer-description p {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .footer-quick-links {
    margin-right: 0;
  }
  .footer-address,
  .footer-quick-links,
  .footer-map iframe {
    width: 100%;
    text-align: left;
    margin-left: 0;
    margin-top: 30px;
  }
  .footer-map iframe {
    margin-top: 20px;
  }
  .footer-address h3,
  .footer-quick-links h3 {
    color: #ee7d27;
    margin-top: 0;
  }
}

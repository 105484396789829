.misc-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 95%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.misc-section {
  margin-bottom: 40px;
}

.misc-title {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #2e3191;
  border-bottom: 2px solid #2e3191;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.misc-title svg {
  margin-right: 10px;
}

.misc-timetable {
  margin-bottom: 20px;
}

.misc-timetable h3 {
  color: #2e3191;
  margin-bottom: 10px;
}

.misc-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.misc-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.misc-table tr:nth-child(even) {
  background-color: #f4f6f5;
}

.misc-table tr:hover {
  background-color: #ddd;
}

.misc-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.misc-activities {
  display: flex;
  flex-wrap: wrap;
}

.misc-activities-section {
  flex: 1;
  min-width: 200px;
  margin-right: 20px;
}

.misc-activities-section h3 {
  color: #2e3191;
  margin-bottom: 10px;
}

.misc-activities-section ul {
  list-style-type: none;
  padding-left: 0;
}

.misc-activities-section ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.misc-activities-section ul li svg {
  margin-right: 10px;
  color: #ee7d27;
}

.misc-celebrations {
  list-style-type: none;
  padding-left: 0;
}

.misc-celebrations li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.misc-celebrations li svg {
  margin-right: 10px;
  color: #ee7d27;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .misc-container {
    width: 95%;
  }

  .misc-title {
    font-size: 20px;
    line-height: 22px;
  }
}

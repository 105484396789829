.about-us-screen-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* About Us Section */

.about-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  padding: 40px;
  background-color: #ffffff;
}

.aboutus-top {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  padding: 15px;
}

.aboutus-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: Arial, sans-serif;
  text-align: left;
  margin-right: 50px;
}

.aboutus-h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: left;
}

.aboutus-p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.aboutus-images {
  display: flex;
  flex: 1;
  margin: 50px 0;
  margin-bottom: 0;
}

.aboutus-images img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.aboutus-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  padding-top: 0;
}

.aboutus-bottom > :nth-child(1),
.aboutus-bottom > :nth-child(2) {
  flex: 1;
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  margin-right: 50px;
}

/* Core Values Section */

.core-values-section {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 120px;
  text-align: center;
}

.vision-tabs {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 60px;
}

.cv-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 20px 30px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #212529;
  transition: background-color 0.3s ease;
}

.cv-tab.active {
  background-color: #ee7d27;
  color: #000;
}

.cv-tab:hover {
  background-color: #ee7d27;
  color: #000;
}

.cv-tab span {
  font-size: 1em;
  margin-top: 20px;
}

.vision-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  background-color: #f4f6f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vision-text {
  text-align: left;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.vision-text h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #ee7d27;
}

.vision-text p {
  font-size: 16px;
}

/* School Management Section */

.sms-section {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.sms-image-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 50px;
}

.sms-image-container img {
  max-width: 90%;
  height: 85%;
  border-radius: 8px;
  object-fit: cover;
}

.sms-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sms-content h2 {
  color: #ee7d27;
  font-size: 3em;
  margin-bottom: 20px;
}

.sms-content p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Our Infrastructure Section */

.oif-section {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.oif-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.oif-content h2 {
  color: #ee7d27;
  font-size: 3em;
  margin-bottom: 20px;
}

.oif-content p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.oif-image-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 50px;
}

.oif-image-container img {
  max-width: 100%;
  height: 300px;
  border-radius: 8px;
}

/* Emphasize on 4C's Section */

.ep4c-section {
  display: flex;
  padding: 40px;
  background-color: #f4f6f5;
}

.ep4c-centred-div {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.ep4c-centred-div h2 {
  color: #ee7d27;
  font-size: 3em;
  margin-bottom: 20px;
  text-align: center;
}

.ep4c-centred-div h3 {
  color: #000000;
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
}

.ep4c-image-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.ep4c-image-container img {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  object-fit: cover;
}

.ep4c-content {
  display: flex;
  padding: 40px;
  background-color: #f4f6f5;
}

.ep4c-content ul li {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .about-section {
    width: 100%;
    padding: 0px;
  }

  .aboutus-top {
    flex-direction: column;
  }

  .aboutus-content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .aboutus-p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: left;
  }

  .aboutus-h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .aboutus-bottom {
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    padding-top: 0;
  }

  .aboutus-p,
  .aboutus-bottom > :nth-child(1),
  .aboutus-bottom > :nth-child(2) {
    font-size: 1em;
    line-height: 1.4;
    margin-right: 0;
    text-align: left;
  }

  .aboutus-images {
    flex-direction: column;
    margin: 20px 0;
  }

  .core-values-section {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .vision-tabs {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .vision-content {
    flex-direction: column;
    padding: 20px;
  }

  .cv-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 20px 30px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #212529;
    transition: background-color 0.3s ease;
  }

  .sms-section,
  .oif-section {
    flex-direction: column;
    width: 100%;
    margin: 20px auto;
  }

  .oif-image-container {
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    justify-items: center;
    align-items: center;
  }

  .sms-image-container {
    display: none;
  }

  .oif-image-container img {
    max-width: 100%;
    height: 220px;
    border-radius: 8px;
  }

  .oif-image-container {
    padding: 20px;
  }

  .sms-content h2,
  .oif-content h2 {
    font-size: 2em;
  }

  .sms-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sms-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .sms-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: left;
  }

  .oif-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .oif-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .oif-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
    text-align: left;
  }

  .ep4c-section {
    padding: 20px;
  }

  .ep4c-centred-div h2,
  .ep4c-centred-div h3 {
    font-size: 24px;
    text-align: center;
  }

  .ep4c-image-container {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .ep4c-content {
    display: flex;
    padding: 0px;
  }
  .ep4c-centred-div {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .ep4c-content ul li {
    margin-bottom: 10px;
    text-align: left;
  }
}

.school-info-container {
  display: flex;
  padding: 20px;
  width: 80%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.school-info-images {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.school-info-images img {
  width: 100%;
  height: 450px;
  object-fit: cover; /* Ensure images cover the area without distortion */
}

.student-image {
  width: 250px;
  height: auto;
  margin-bottom: 10px;
}

.school-info {
  flex: 1;
}

.school-info-title {
  color: #ee7d27;
  font-weight: bold;
}

.school-info h2 {
  color: #000000;
  margin-bottom: 20px;
}

.info-section {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
}

.info-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f4f6f5;
}

.info-content {
  padding: 10px;
  background: #ffffff;
  transition: all 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .school-info-container {
    flex-direction: column; /* Change to column layout on smaller screens */
    align-items: center;
  }

  .school-info-images {
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 20px; /* Add space below images */
    align-items: center;
  }

  .school-info-images img,
  .student-image {
    width: 100%;
    height: auto; /* Adjust image size */
  }

  .school-info {
    width: 100%;
    text-align: center; /* Center text on smaller screens */
  }

  .info-header,
  .info-content {
    padding: 15px; /* Adjust padding for better touch targets on smaller screens */
  }
}

@media (max-width: 768px) {
  .school-info-title,
  .school-info h2 {
    text-align: center; /* Center titles on smaller screens */
  }

  .info-header,
  .info-content {
    font-size: 0.9rem; /* Adjust font size for readability on small screens */
  }

  .school-info-container {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .school-info-images img {
    height: auto; /* Allow image height to adjust */
  }
}
